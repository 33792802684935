.info {
	padding-top: 30px;
	padding-bottom: 30px;
}

.info p {
    font-size: 1.0em !important;
    line-height: 1.7em !important;
}

.info h2 {
    font-size: 1.5em !important;
    line-height: 1.6em !important;
}

.info-dark {
	background-color: #ddd;
}

.picture-one {
	background: url("../img/slideshow_2/6239_Bhend_Thundorf_Aussen_K10_180321.jpg") no-repeat center center; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 100%;
}

.picture-two {
	background: url("../img/slideshow_2/6239_Bhend_Thundorf_Innen_DG_K03_160321.jpg") no-repeat center center; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 100%;
}

.picture-three {
	background: url("../img/slideshow_2/6239_Bhend_Thundorf_Innen_DG_K01_160321.jpg") no-repeat center center; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 100%;
}

#content-wrap {
	padding-bottom: 30.0rem;
	/* Footer height */
}


p {
	hyphens: auto;
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1000px; } }
